<template>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="12"
      :xl="12"
    >
      <div class="heading">
        <h1 v-if="realtorid">
         Images
        </h1>
      </div>
    </el-col>
    <el-col
      :xs="6"
      :sm="6"
      :md="6"
      :lg="12"
      :xl="12"
    >
      <div class="topsearch">
        <el-button
          type="success"
          @click="submitForm('ruleForm')"
        >
          Save
        </el-button>
      </div>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col
      :xs="24"
      :sm="24"
      :md="24"
      :lg="24"
      :xl="24"
    >
      <div v-if="showAlertAdded">
        <el-alert
          v-if="!realtorid"
          title="Realtor Added Successfully"
          type="success"
          effect="dark"
        />
        <el-alert
          v-if="realtorid"
          title="Realtor Updated Successfully"
          type="success"
          effect="dark"
        />
      </div>
    </el-col>
  </el-row>
 
   <el-form
    ref="ruleForm"
    :model="data"
    status-icon
    :rules="rules"
    label-width="180px"
    class=" login-form"
    :label-position="labelPosition"
  >
    <div class="panel panel-default">
      <div
        class="panel-title"
        style="padding-bottom: 25px;margin-bottom: 20px;"
      >
        <i class="fa fa-bars" /> Images Settings
      </div>
       
        <el-form-item 
            label="Portrait" 
            prop="config_portrait"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileList"
              :limit="1"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleImageUploadSuccess"
              :on-error="handleImageUploadError"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/> JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item 
            label="Website Logo" 
            prop="config_logo"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListLogo"
              :limit="1"
              :on-preview="handlePreviewLogo"
              :on-remove="handleRemoveLogo"
              :on-success="handleImageUploadSuccessLogo"
              :on-error="handleImageUploadErrorLogo"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/> JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

           <el-form-item 
            label="Second Website Logo" 
            prop="config_websitelogo2"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListwebsitelogo2"
              :limit="1"
              :on-preview="handlePreviewwebsitelogo2"
              :on-remove="handleRemovewebsitelogo2"
              :on-success="handleImageUploadSuccesswebsitelogo2"
              :on-error="handleImageUploadErrorwebsitelogo2"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/> JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item 
            label="Third Website Logo" 
            prop="config_websitelogo3"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListwebsitelogo3"
              :limit="1"
              :on-preview="handlePreviewwebsitelogo3"
              :on-remove="handleRemovewebsitelogo3"
              :on-success="handleImageUploadSuccesswebsitelogo3"
              :on-error="handleImageUploadErrorwebsitelogo3"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item 
            label="Office Logo" 
            prop="config_officelogo"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListofficelogo"
              :limit="1"
              :on-preview="handlePreviewofficelogo"
              :on-remove="handleRemoveofficelogo"
              :on-success="handleImageUploadSuccessofficelogo"
              :on-error="handleImageUploadErrorofficelogo"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item 
            label="Icon" 
            prop="config_icon"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListIcon"
              :limit="1"
              :on-preview="handlePreviewIcon"
              :on-remove="handleRemoveIcon"
              :on-success="handleImageUploadSuccessIcon"
              :on-error="handleImageUploadErrorIcon"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/> JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

           <el-form-item 
            label="Sold Ribbon" 
            prop="config_sold_ribbon"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListSoldRibbon"
              :limit="1"
              :on-preview="handlePreviewSoldRibbon"
              :on-remove="handleRemoveSoldRibbon"
              :on-success="handleImageUploadSuccessSoldRibbon"
              :on-error="handleImageUploadErrorSoldRibbon"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item 
            label="Open House Ribbon" 
            prop="config_sold_ribbon"
          >
            <el-upload
              class="upload-demo"
              drag
              :action="imageUploadUrl"
              list-type="picture"
              :headers="imageUploadHeaders"
              name="file"
              :file-list="fileListOpenHouseRibbon"
              :limit="1"
              :on-preview="handlePreviewOpenHouseRibbon"
              :on-remove="handleRemoveOpenHouseRibbon"
              :on-success="handleImageUploadSuccessOpenHouseRibbon"
              :on-error="handleImageUploadErrorOpenHouseRibbon"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em><br/>JPEG,PNG,JPG,GIF,SVG,WEBP files with a size less than 10M
              </div>
            </el-upload>
          </el-form-item>

          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Sold Ribbon Position"
            prop="config_sold_ribbon_position"
            >
            <el-select v-model="data.config_sold_ribbon_position">
              <el-option
              v-for="item in arrayribbonposition"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>

            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12"> 
            <el-form-item
            label="Open House Ribbon Position"
            prop="config_openhouse_ribbon_position"
            >
            <el-select v-model="data.config_openhouse_ribbon_position">
              <el-option
              v-for="item in arrayribbonposition"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Property Image Thumb Size"
            >
             <el-input 
                  v-model="data.config_image_thumb_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_thumb_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Website Logo Image Size"
            >
             <el-input 
                  v-model="data.config_image_logo_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_logo_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>
         

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Second Website Logo Image Size"
            >
             <el-input 
                  v-model="data.config_websitelogo2_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_websitelogo2_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Third Website Logo Image Size"
            >
             <el-input 
                  v-model="data.config_websitelogo3_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_websitelogo3_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Office Logo Image Size"
            >
             <el-input 
                  v-model="data.config_officelogo_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_officelogo_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Brokerage Logo Image Size"
            >
             <el-input 
                  v-model="data.config_brokeragelogo_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_brokeragelogo_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Property Image Popup Size"
            >
             <el-input 
                  v-model="data.config_image_popup_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_popup_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Slideshow Image Size"
            >
             <el-input 
                  v-model="data.config_slider_image_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_slider_image_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Property Slider Image"
            >
             <el-input 
                  v-model="data.config_image_propertyslider_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_propertyslider_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Agent Image Size"
            >
             <el-input 
                  v-model="data.config_image_agent_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_agent_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

          <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Blog Thumb Image Size"
            >
             <el-input 
                  v-model="data.config_image_blogthumb_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_blogthumb_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Blog Popup Image Size"
            >
             <el-input 
                  v-model="data.config_image_blogpopup_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_image_blogpopup_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Home Property Image Size"
            >
             <el-input 
                  v-model="data.config_homeproperty_image_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_homeproperty_image_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

         <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Property Info Thumb Size"
            >
             <el-input 
                  v-model="data.config_propertyinfo_thumb_image_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_propertyinfo_thumb_image_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Community Logo Size"
            >
             <el-input 
                  v-model="data.config_community_logo_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_community_logo_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Community Thumb Image Size"
            >
             <el-input 
                  v-model="data.config_community_thumb_image_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_community_thumb_image_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="Video Thumb Size"
            >
             <el-input 
                  v-model="data.config_videothumb_width" 
                  type="text" 
                  placeholder="Width"
                />               
            </el-form-item>
          </el-col>
         <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
            <el-form-item
            label="&nbsp;"
            >
            <el-input 
                  v-model="data.config_videothumb_height" 
                  type="text" 
                  placeholder="Height"
                />
            </el-form-item>
         </el-col>
        </el-row>
        
    </div>
  </el-form>
</template>
<script>
import UserService from "@/api/user.service";
import SettingService from "@/api/setting.service";
import authHeader from '@/api/auth-header';
  export default {
    data() {
         var checkMlsid = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Please Enter the MLS®/Board user ID.'));
        } else {
          callback();
        }
      };      
      return {
        showAlertAdded: false,
        labelPosition: 'top',
        domainsid: null,
        imageUploadUrl: process.env.VUE_APP_BASE_API,
        imageUploadHeaders: null,
        fileList: [],
        fileListLogo: [],
        fileListwebsitelogo2:[],
        fileListwebsitelogo3:[],
        fileListofficelogo:[],
        fileListIcon:[],
        fileListSoldRibbon:[],
        fileListOpenHouseRibbon:[],
        arrayribbonposition:[{
          value: 'topleft',
          label: 'TOP Left'
        },{
          value: 'topcenter',
          label: 'TOP CENTER'
        },{
          value: 'topright',
          label: 'TOP RIGHT'
        },{
          value: 'bottomleft',
          label: 'TOP Left'
        },{
          value: 'bottomcenter',
          label: 'BOTTOM CENTER'
        },{
          value: 'bottomright',
          label: 'BOTTOM RIGHT'
        },{
          value: 'imgcenter',
          label: 'CENTER OF Image'
        }],
        data: {
          config_admin_style: null,
          config_admin_script:null
        },
        rules: {
          config_admin_style: [
            { validator: checkMlsid, trigger: 'blur' }
          ],
        }
      };
    },     
	mounted() {
      this.getdomains();   
      this.setImageUploadUrl();   
  },
   methods: {
       submitForm(formName) {
        this.$refs[formName].validate((valid) => {
         if (valid && this.domainsid) {
            return SettingService.updateImagesetting(this.domainsid, this.data).then(response => {
              this.showAlertAdded=true;
            });
          }else {
            console.log('error submit!!');
            return false;
          }
        });
      },handleFileUpload(){

      },
	getdomains(){
        return UserService.getUserBoard().then(response => {
            this.domainsid =response.data.domains[0].id;
            this.getsetting();
        });
        },
        getsetting(){
        return SettingService.get(this.domainsid).then(response => {
        this.data = response.data;

        if(response.data.config_portrait_view){
          this.fileList.push({name:'', url: response.data.config_portrait_view});
        }
        if(response.data.config_logo_view){
          this.fileListLogo.push({name:'', url: response.data.config_logo_view}); 
        }
         if(response.data.config_websitelogo2_view){
          this.fileListwebsitelogo2.push({name:'', url: response.data.config_websitelogo2_view}); 
         }
        if(response.data.config_websitelogo3_view){
          this.fileListwebsitelogo3.push({name:'', url: response.data.config_websitelogo3_view});
        } 
        if(response.data.config_officelogo_view){
          this.fileListofficelogo.push({name:'', url: response.data.config_officelogo_view});
        }
        if(response.data.config_icon_view){
          this.fileListIcon.push({name:'', url: response.data.config_icon_view});
        }
        if(response.data.config_sold_ribbon_view){
          this.fileListSoldRibbon.push({name:'', url: response.data.config_sold_ribbon_view});
        }
        if(response.data.config_openhouse_ribbon_view){
          this.fileListOpenHouseRibbon.push({name:'', url: response.data.config_openhouse_ribbon_view});
        }  
      });
	},
  setImageUploadUrl() {
        this.imageUploadHeaders = authHeader();
        this.imageUploadUrl = process.env.VUE_APP_BASE_API + 'image-upload';
      },
      handleImageUploadSuccess(response) {
        if(response.code == 200) {
          this.data.config_portrait = response.image_url;
        }
      },
      handleImageUploadError(err) {
        console.log(err)
      },
      handlePreview(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemove(file, fileList) {
        console.log(file, fileList)
        //TODO: Delete the file
      },

      handleImageUploadSuccessLogo(response) {
        if(response.code == 200) {
          this.data.config_logo = response.image_url;
        }
      },
      handleImageUploadErrorLogo(err) {
        console.log(err)
      },
      handlePreviewLogo(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveLogo(file, fileListLogo) {
        console.log(file, fileListLogo)
        //TODO: Delete the file
      },

      handleImageUploadSuccesswebsitelogo2(response) {
        if(response.code == 200) {
          this.data.config_websitelogo2 = response.image_url;
        }
      },
      handleImageUploadErrorwebsitelogo2(err) {
        console.log(err)
      },
      handlePreviewwebsitelogo2(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemovewebsitelogo2(file, fileListwebsitelogo2) {
        console.log(file, fileListwebsitelogo2)
        //TODO: Delete the file
      },

      handleImageUploadSuccesswebsitelogo3(response) {
        if(response.code == 200) {
          this.data.config_websitelogo3 = response.image_url;
        }
      },
      handleImageUploadErrorwebsitelogo3(err) {
        console.log(err)
      },
      handlePreviewwebsitelogo3(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemovewebsitelogo3(file, fileListwebsitelogo3) {
        console.log(file, fileListwebsitelogo3)
        //TODO: Delete the file
      },

      handleImageUploadSuccessofficelogo(response) {
        if(response.code == 200) {
          this.data.config_officelogo = response.image_url;
        }
      },
      handleImageUploadErrorofficelogo(err) {
        console.log(err)
      },
      handlePreviewofficelogo(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveofficelogo(file, fileListofficelogo) {
        console.log(file, fileListofficelogo)
        //TODO: Delete the file
      },

      handleImageUploadSuccessIcon(response) {
        if(response.code == 200) {
          this.data.config_icon = response.image_url;
        }
      },
      handleImageUploadErrorIcon(err) {
        console.log(err)
      },
      handlePreviewIcon(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveIcon(file, fileListIcon) {
        console.log(file, fileListIcon)
        //TODO: Delete the file
      },

      handleImageUploadSuccessSoldRibbon(response) {
        if(response.code == 200) {
          this.data.config_sold_ribbon = response.image_url;
        }
      },
      handleImageUploadErrorSoldRibbon(err) {
        console.log(err)
      },
      handlePreviewSoldRibbon(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveSoldRibbon(file, fileListSoldRibbon) {
        console.log(file, fileListSoldRibbon)
        //TODO: Delete the file
      },

      handleImageUploadSuccessOpenHouseRibbon(response) {
        if(response.code == 200) {
          this.data.config_openhouse_ribbon = response.image_url;
        }
      },
      handleImageUploadErrorOpenHouseRibbon(err) {
        console.log(err)
      },
      handlePreviewOpenHouseRibbon(file) {
        console.log(file)
        //TODO: Any Preview stuff
      },
      handleRemoveOpenHouseRibbon(file, fileListOpenHouseRibbon) {
        console.log(file, fileListOpenHouseRibbon)
        //TODO: Delete the file
      },
    }
  }
</script>
<style lang="scss" scoped>
 .panel-title{
      border-bottom: 1px solid #9a83835c;
      padding-bottom: 10px;
      margin-bottom: 10px; 
      text-align: left;
  }

.panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: #fff;
    border: 1px solid #E6E9ED;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}
.el-row {
    margin-bottom: 0px !important;
}
.el-form--label-top .el-form-item__label{
      float: left !important;
      font-weight: 500;
      font-size: 12px;
      padding: 0 0 0px 0;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }  
  .heading {
  text-align: left;
  width: 100%;
  line-height: 2;
}
div .topsearch {
    float: right;
    display: inline-flex;
    padding: 15px 0px;
  }
div .topsearch .el-input__inner{
  padding-right:10px;
  height: 40px;
  width: 500px;
}
.el-select{
     width: 100%;
}
div .topsearch .el-input{
  padding-right:10px;
}
.upload-demo{ display: flex;gap: 20px;width: 100%;max-width:100%;}
@media screen and (max-width: 767px) {
.upload-demo{ display: unset; gap: unset;width: unset;max-width:unset;}
}
</style>